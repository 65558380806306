const Data = [
    {
        id: 1,
        category: "education",
        icon: "icon-graduation",
        year: "2018 - 2022",
        title: "Bachelor's Degree",
        desc: "I completed my Bachelor's Degree in Software Engineering from University Sabah Malaysia (UMS)",
    },
    {
        id: 2,
        category: "education",
        icon: "icon-graduation",
        year: "2022 - 2024",
        title: "Petronas Digital's Young Graduate Program (YGP)",
        desc: "I've participated in the YGP program since 4th Oct 2022 and completed it in 31st May 2024",
    },
    {
        id: 4,
        category: "experience",
        icon: "icon-briefcase",
        year: "Feb 2016 - Jun 2016",
        title: "Tutor",
        desc: "I worked as a tutor in a tuition center and teach students from 6 - 17 years old according to their respective lessons.",
    },
    {
        id: 5,
        category: "experience",
        icon: "icon-briefcase",
        year: "Jan 2018 - Aug 2018",
        title: "Sales Assistant",
        desc: "I keep track all the items sold in the company, sometimes I work as a cashier too.",
    },
    {
        id: 6,
        category: "experience",
        icon: "icon-briefcase",
        year: "June 2019 - Sept 2019",
        title: "Sales Assistant",
        desc: "I worked in a duty free shop located in KKIA, I hit my sales every months in order to gain more commission.",
    },
    {
        id: 7,
        category: "experience",
        icon: "icon-briefcase",
        year: "March 2022 - Aug 2022",
        title: "Flutter Developer Intern",
        desc: "This is where I had my first journey in software development career. I have to learn as fast as I can in order to deliver the tasks that has been assigned to me. The startup company has provided me rich experience in terms of working with different developers and worked undeer a very tight schedule.",
    },
    {
        id: 8,
        category: "experience",
        icon: "icon-briefcase",
        year: "4th Oct - Present",
        title: "Software Engineer",
        desc: "I'm grateful to have a supportive Manager who provided me the opportunity to have a handson experience with DevOps, CI/CD, VMs, Azure App Services and many more.",
    },
];
  
export default Data