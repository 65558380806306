import React from 'react'
import "./services.css"
import ServiceImage1 from "../../assets/service-1.svg"
import ServiceImage2 from "../../assets/service-2.svg"
import ServiceImage3 from "../../assets/devops.png"

const Services = () => {
  const data = [
    {
      id: 1,
      image: ServiceImage1,
      title: "UI/UX design",
      description:
        "I offer comprehensive UI/UX design services, specializing in creating intuitive and engaging user interfaces and experiences.",
    },
    {
      id: 2,
      image: ServiceImage2,
      title: "Web Development",
      description:
        "My services include developing custom web applications, improving existing websites, and integrating third-party APIs.",
    },
    {
      id: 3,
      image: ServiceImage3,
      title: "DevOps",
      description:
        "With expertise in continuous integration and continuous delivery (CI/CD) pipelines, infrastructure as code (IAC), and cloud services like Azure, I ensure efficient and reliable deployment workflows.",
    },
  ];
  
  return (
    <section className="services container section" id='services'>
      <h2 className="section__title">Services</h2>

      <div className="services__container grid">
        {data.map(({id, image, title, description}) => {
          return (
            <div className="services__card" key={id}>
              <img src={image} alt="" className="services__img" style={{width: "100px"}}/>
              <h3 className="services__title">{title}</h3>
              <p className="services__description">{description}</p>
            </div>
          );
        })}
      </div>
    </section>
  )
}

export default Services