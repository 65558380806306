import React, { useState } from 'react'
import "./contact.css"
import app from "../../FirebaseConfig"
import { getDatabase, ref, set, push } from 'firebase/database'
import emailjs from 'emailjs-com';

const Contact = () => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const validateForm = () => {
    const newErrors = {};
    if (!name) newErrors.name = "Name is required";
    if (!email) newErrors.email = "Email is required";
    if (!subject) newErrors.subject = "Subject is required";
    if (!message) newErrors.message = "Message is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const sendMessage = async () => {
    const db = getDatabase(app);
    const newDocRef = push(ref(db, "client/message"));
    set(newDocRef, {
      clientName: name,
      clientEmail: email,
      subject: subject,
      message: message
    }).then(() => {
      alert("Message sent successfully!")
    }).catch((err)=>{
      alert("Error: ", err.message);
    })
  }

  const sendEmail = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    const templateParams = {
      from_name: name,
      from_email: email,
      subject: subject,
      message: `${message}`,
      to_name: "Ivan",
      to_email: "ivanlim608@gmail.com"
    };

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID, 
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID, 
      templateParams,
      process.env.REACT_APP_EMAILJS_USER_ID,
    ).then((result) => {
      console.log('Email sent!', result.status, result.text);
      window.location.reload();
    }).catch((error) => {
      console.log('Email sending error:', error);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      sendMessage();
      sendEmail(e);
    }
  };

  return (
    <section className="contact container section" id="contact">
      <h2 className="section__title">Get In Touch</h2>

      <div className="contact__container grid">
        <div className="contact__info">
          <h3 className="contact__title">Let's talk about everything!</h3>
          <p className="contact__details">Don't like forms? Send me an email. 👋</p>
        </div>

        <form className="contact__form" onSubmit={handleSubmit}>
          <div className="contact__form-group">
            <div className="contact__form-div">
              <input 
                type="text" 
                className="contact__form-input" 
                placeholder='Insert your name' 
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {errors.name && <p className="error">{errors.name}</p>}
            </div>

            <div className="contact__form-div">
              <input 
                type="email" 
                className="contact__form-input" 
                placeholder='Insert your email' 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>

            
          </div>
          <div className="contact__form-div">
              <input 
                type="text" 
                className="contact__form-input" 
                placeholder='Insert your subject' 
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              {errors.subject && <p className="error">{errors.subject}</p>}
            </div>

            <div className="contact__form-div contact__form-area">
              <textarea 
                name="" 
                id="" 
                cols={30} 
                rows={10} 
                className='contact__form-input' 
                placeholder='Write your message' 
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              >
              </textarea>
              {errors.message && <p className="error">{errors.message}</p>}
            </div>
          <button type='submit' className='btn'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact