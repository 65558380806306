import React, { useState } from 'react'
import "./sidebar.css"
import Logo from "../../assets/logo.svg"

const Sidebar = () => {
  const [toggle, setToggle] = useState(false);

  const navItems = [
    { href: "#home", iconClass: "icon-home" },
    { href: "#about", iconClass: "icon-user-following" },
    { href: "#services", iconClass: "icon-briefcase" },
    { href: "#resume", iconClass: "icon-graduation" },
    { href: "#portfolio", iconClass: "icon-layers" },
    { href: "#blog", iconClass: "icon-note" },
    { href: "#contact", iconClass: "icon-bubble" },
  ];

  return (
    <>
      <aside className={toggle ? "aside show-menu" : "aside"}>
        <a href="#home" className="nav__logo">
          <img src={Logo} alt=''/>
        </a>

        <nav className='nav'>
          <div className='nav__menu'> 
            <ul className="nav__list">
              {navItems.map((item, index) => (
                <li key={index} className="nav__item">
                  <a href={item.href} className="nav__link">
                    <i className={item.iconClass}></i>
                  </a>
                </li>
                ))
              }
            </ul>
          </div>
        </nav>

        <div className="nav__footer">
          <span className="copyright">
            &copy; 2023 - 2024.
          </span>
        </div>
      </aside>

      <div className={toggle ? "nav__toggle nav__toggle-open" : "nav__toggle"} onClick={()=>{setToggle(!toggle)}}>
        <i className="icon-menu"></i>
      </div>
    </>
  )
}

export default Sidebar