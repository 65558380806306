import React from 'react'

const HeaderSocials = () => {

    const socialItems = [
        { href: "https://www.instagram.com/ivannnnlck/?hl=en", iconClass: "fa-brands fa-instagram" },
        { href: "https://www.facebook.com/ivan.lim.921677/", iconClass: "fa-brands fa-facebook" },
        { href: "https://www.linkedin.com/public-profile/settings?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_self_edit_contact-info%3BwsgQcNIbTTmFVQqPTfl7cg%3D%3D", iconClass: "fa-brands fa-linkedin" },
    ];
  return (
    <div className="home__socials" style={{ display: "flex", flexDirection: "row", justifyContent: "center", columnGap: "1.75rem", margin: "1.75rem 0"}}>
      {socialItems.map((item, index) => (
        <a key={index} href={item.href} className="home__socials-link" style={{ margin: "0 10px" }}>
          <i className={item.iconClass}></i>
        </a>
      ))}
    </div>
  )
}

export default HeaderSocials