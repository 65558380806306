import React from 'react'

const AboutBox = () => {
    const aboutBoxes = [
        { iconClass: "icon-fire", title: "15", subtitle: "Supported project" },
        { iconClass: "icon-cup", title: "5670", subtitle: "Cup of coffee" },
        { iconClass: "icon-people", title: "2", subtitle: "Satisfied clients" },
        { iconClass: "icon-badge", title: "15", subtitle: "Achievements" },
    ];

    return (
        <div className="about__boxes grid">
            {aboutBoxes.map((box, index) => (
          <div key={index} className="about__box">
            <i className={`about__icon ${box.iconClass}`}></i>
            <div>
              <h3 className="about__title">{box.title}</h3>
              <span className="about__subtitle">{box.subtitle}</span>
            </div>
          </div>
        ))}
        </div>
    )
}

export default AboutBox