import Image1 from '../../assets/avatar-1.svg';
import Image2 from '../../assets/avatar-2.svg';
import Image3 from '../../assets/avatar-3.svg';

export const data = [
    {
      id: 1,
      image: Image1,
      title: "Ziyad",
      subtitle: "Backend Developer at CMA Solutions Sdn. Bhd.",
      comment:
        "Able to work in a fast-paced environment. Quickly completed his task. Able to adapt to changes during development. Excellent problem solving skills. Self reliant. Fast learner.",
    },
    {
      id: 2,
      image: Image2,
      title: "Abdul Aziz Bin Din",
      subtitle: "Frontend Developer at CMA Solutions Sdn. Bhd.",
      comment:
        "Fast learner, able to learn Flutter & apply the skill during development. Good problem solving skill, able to complete complex tasks. Always have positive mindset even in tense environment.",
    },
    {
        id: 3,
        image: Image3,
        title: "Kong Hau Siong",
        subtitle: "Backend Developer at Xenber Sdn. Bhd.",
        comment:
          "Frequently completed task on time. Good attitude. Positive mental attitude. Able to adapt with new technology.",
      },
];

