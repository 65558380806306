import React from 'react'
import "./pricing.css"
import Image1 from "../../assets/price-1.svg";
import Image2 from "../../assets/price-2.svg";
import Image3 from "../../assets/price-3.svg";

const Pricing = () => {

  const pricingItems = [
    {
      id: 1,
      image: Image1,
      pricingPlan: 'Basic',
      pricingTitle: 'A Simple option but powerful to manage your business',
      pricingSupport: 'Email support',
      price: '350',
    },
    {
      id: 2,
      image: Image2,
      pricingPlan: 'Premium',
      pricingTitle: 'Unlimited product including app integration and more features',
      pricingSupport: 'Mon-Fri support',
      price: '800',
    },
    {
      id: 3,
      image: Image3,
      pricingPlan: 'Ultimate',
      pricingTitle: 'A wise option for large companies and individuals',
      pricingSupport: '24/7 support',
      price: '2500',
    },
  ];

  return (
    <section className="pricing container section" id="pricing">
      <h2 className="pricing__title">Pricing Plans</h2>

      <div className="pricing__container grid">
        {
          pricingItems.map((data) => {
            return (
              <div key={data.id} className={`pricing__item ${data.id === 2 ? 'best' : ''}`}>
                {data.id === 2 ? <span className='badge'>Recommended</span> : <></>}
                <img src={data.image} alt="" className="pricing__img" />
                <h3 className="pricing__plan">{data.pricingPlan}</h3>
                <p className="pricing__title">{data.pricingTitle}</p>
                <p className="pricing__support">{data.pricingSupport}</p>
                <h3 className="price">
                  <em>RM</em> {data.price} <span>Month</span>
                </h3>
                <a href="" className="btn">Get Started</a>
              </div>
            )
          })
        }
      </div>
    </section>
  )
}

export default Pricing