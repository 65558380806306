import React from 'react'
import "./about.css"
import AboutAvatar from "../../assets/avatar-2.svg"
import AboutBox from './AboutBox';

const About = () => {

  const skills = [
    { name: "Development", percentage: "90%", className: "development" },
    { name: "UI/UX design", percentage: "80%", className: "uidesign" },
    { name: "DevOps", percentage: "70%", className: "devops" },
  ];

  return (
    <section className="about container section" id='about'>
      <h2 className="section__title">About Me</h2>

      <div className="about__container grid">
        <img src={AboutAvatar} alt="" className="about__img" />

        <div className="about__data grid">
          <div className="about__info">
            <p className="about__description">
              I am Ivan Lim, web developer from Sabah, Malaysia. I have experience in web site design and building and customization, also I am good at DevOps.
              <a href="/Ivan_Lim_Choon_Kiat_Resume.pdf" className="btn" download="Ivan_Lim_Choon_Kiat_Resume.pdf">Download CV</a>
            </p>
          </div>

          <div className="about__skills grid">
            {skills.map((skill, index) => (
              <div key={index} className="skills__data">
                <div className="skills__titles">
                  <h3 className="skills__name">{skill.name}</h3>
                  <span className={`skills__number `}>{skill.percentage}</span>
                </div>
                <div className="skills__bar">
                  <span className={`skills__percentage ${skill.className}`} style={{ width: skill.percentage }}></span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <AboutBox/>
    </section>
  )
}

export default About