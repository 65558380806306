import React, { useState } from 'react'
import "./portfolio.css"
import Menu from './Menu'

const Portfolio = () => {
  const workItem = [
    {
      id: 1, 
      item: "Everything"
    },
    {
      id: 2, 
      item: "Creative"
    },
    {
      id: 3, 
      item: "Collaboration"
    },
    {
      id: 4, 
      item: "Design"
    },
    {
      id: 5, 
      item: "Branding"
    },
  ]

  const [items, setItems] = useState(Menu);

  const filterItem = (category) => {
    if (category === "Everything") {
      setItems(Menu); // If the category is "Everything", set all items
      return;
    }
  
    const updateItem = Menu.filter((currentElement) => {
      return currentElement.category === category;
    });
  
    setItems(updateItem);
  };

  return (
    <section className="work container section" id="portfolio">
      <h2 className="section__title">Recent Works</h2>

      <div className="work__filters">
        {
          workItem.map((items) => {
            return (
              <span className="work__item" key={items.id} onClick={() => filterItem(items.item)}>{items.item}</span>
            )
          })
        }
      </div>

      <div className="work__container grid">
        {items.map((elem) => {
          const{id, image, title, category} = elem;
          return (
            <div className="work__card" key={id}>
              <div className="work__thumbnail">
                <img src={image} alt="" className="work__img" />
                <div className="work__mask"></div>
              </div>

              <span className="work__category">{category}</span>
              <h3 className="work__title">{title}</h3>
              <a href="" className="work__button">
                <i className="icon-link work__button-icon"></i>
              </a>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default Portfolio