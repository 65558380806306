import React from 'react'
import "./blog.css"
import Image1 from '../../assets/blog-1.svg';
import Image2 from '../../assets/blog-2.svg';
import Image3 from '../../assets/blog-3.svg';

const Blog = () => {
  const blogData = [
    {
      id: 1,
      image: Image1,
      cateogry: 'Reviews',
      title: '5 Best App Development Tool for Your Projects',
      meta: '09 February, 2022',
      user: 'Ivan'
    },
    {
      id: 2,
      image: Image2,
      cateogry: 'Tutorial',
      title: 'Common Misconceptions About Payment',
      meta: '07 February, 2022',
      user: 'Ivan'
    },
    {
      id: 3,
      image: Image3,
      cateogry: 'Business',
      title: '3 Things to know about startup business',
      meta: '05 February, 2022',
      user: 'Ivan'
    }
  ];
  return (
    <div className="section blog container" id="blog">
      <h2 className="section__title">Latest Posts</h2>

      <div className="blog__container grid">
        {
          blogData.map((items) => (
            <div className="blog__card">
              <div className="blog__thumb">
                <a href="#"><span className="blog__category">{items.cateogry}</span></a>
                <a href="#"><img src={items.image} alt="" className='blog__img'/></a>
              </div>
              <div className="blog__details">
                <h3 className='blog__title'>{items.title}</h3>
                <div className="blog__meta">
                  <span>{items.meta}</span>
                  <span className="blog__dot">.</span>
                  <span>{items.user}</span>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default Blog